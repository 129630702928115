<template>
  <div class="platform">
    <div class="container-bg">
      <p>
        <span class="ball1"></span>
      </p>
      <p>
        <span class="ball2"></span>
      </p>
      <p>
        <span class="ball3"></span>
      </p>
    </div>
    <div class="platform-container">
      <div class="header">
        <div class="left">
          <section>
            <h3>{{ realTime }}</h3>
            <p>
              {{ date }}
              <span>星期{{ weekSign }}</span>
            </p>
          </section>
        </div>
        <div class="center">
          <section>
            <img src="../../assets/images/home/solutions.png" alt srcset />
          </section>
          <section>
            <h3>英海瑞特「ITS」服务平台</h3>
            <p>INHERITECH INTELLIGENT TRANSPOORTATION SYSTEM</p>
          </section>
        </div>
        <div class="right">
          <section>
            <img src="../../assets/images/home/header.png" alt srcset />
          </section>
          <section>
            <p>{{ realname }}</p>
            <p @click="confirmLogout">
              <i class="iconfont its_tuichu"></i>退出
            </p>
          </section>
        </div>
      </div>
      <!-- 最外层盒子 -->
      <div id="nav">
        <div class="nav-inner">
          <div class="none-box"></div>
          <div class="main">
            <div class="otherStyle">
              <div class="log-Info">
                <img
                  style="width: 100%"
                  src="../../assets/images/home/Bitmap.png"
                  alt=""
                />
                <div class="log-list">
                  <div
                    class="log-item"
                    v-for="(ele, index) in logList"
                    :key="index"
                  >
                    <p class="log-title">{{ ele.time }}</p>
                    <div v-html="ele.content" class="log-p"></div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div class="grids">
              <div class="item-right">
                <div class="item item2" @click="toSystem(1)">
                  <section>
                    <p>
                      <span>
                        <img
                          src="../../assets/images/home/home1.png"
                          alt
                          srcset
                        />
                      </span>
                    </p>
                    <h3>世畅纬佳车辆监控平台</h3>
                  </section>
                </div>
                <div class="item item3" @click="toSystem(2)">
                  <section>
                    <p>
                      <span>
                        <img
                          src="../../assets/images/home/home2.png"
                          alt
                          srcset
                        />
                      </span>
                    </p>
                    <h3>公交主动安全智能调度系统</h3>
                  </section>
                </div>
                <div class="item item7" @click="toSystem(7)">
                  <section>
                    <p>
                      <span>
                        <img
                          src="../../assets/images/home/home7.png"
                          alt
                          srcset
                        />
                      </span>
                    </p>
                    <h3>绩效考核</h3>
                  </section>
                </div>
                <div class="item item4" @click="toSystem(3)">
                  <section>
                    <p>
                      <span>
                        <img
                          src="../../assets/images/home/home3.png"
                          alt
                          srcset
                        />
                      </span>
                    </p>
                    <h3>出租车服务平台</h3>
                  </section>
                </div>
                <div class="item item10" @click="toSystem(10)">
                  <section>
                    <p>
                      <span>
                        <img
                          src="../../assets/images/home/home15.png"
                          alt
                          srcset
                        />
                      </span>
                    </p>
                    <h3>安云财务</h3>
                  </section>
                </div>
                <div class="item item5" @click="toSystem(4)">
                  <section>
                    <p>
                      <span>
                        <img
                          src="../../assets/images/home/home4.png"
                          alt
                          srcset
                        />
                      </span>
                    </p>
                    <h3>资源管理</h3>
                  </section>
                </div>
                <div class="item item6" @click="toSystem(6)">
                  <section>
                    <p>
                      <span>
                        <img
                          src="../../assets/images/home/homegysm.png"
                          alt
                          srcset
                        />
                      </span>
                    </p>
                    <h3>融合营销</h3>
                  </section>
                </div>
                <div class="item item8" @click="toSystem(8)">
                  <section>
                    <p>
                      <span>
                        <img
                          src="../../assets/images/home/home8.png"
                          alt
                          srcset
                        />
                      </span>
                    </p>
                    <h3>数据智能服务</h3>
                  </section>
                </div>
                <div class="item item11" @click="toSystem(11)">
                  <section>
                    <p>
                      <span>
                        <img
                          src="../../assets/images/home/home16.svg"
                          alt
                          srcset
                        />
                      </span>
                    </p>
                    <h3>智慧经营</h3>
                  </section>
                </div>
                <div class="item item9" @click="toSystem(5)">
                  <section>
                    <p>
                      <span>
                        <img
                          src="../../assets/images/home/home5.svg"
                          alt
                          srcset
                        />
                      </span>
                    </p>
                    <h3>车辆调度</h3>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div class="none-box"></div>
        </div>
      </div>
      <div class="footer">Copyright © 2021 四川英海瑞特科技有限公司</div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import { loginOut } from "@/api/lib/api.js";
import { formatDay, menuRecursion, formatHour } from "@/common/utils/index.js";
import baseConfig from "@/api/static/config";
export default {
  data() {
    return {
      date: formatDay(new Date()),
      realname: null,
      realTime: null,
      timer: null,
      token: sessionStorage.getItem("token") || localStorage.getItem("token"),
      logList: [
      {
          time: "2024年6月11日更新内容：",
          content:
            "1、按相关要求取消报警处理时的报警判定中的误报选项，相关统计报表取消排除误报选项。<br/>2、报警处理方式选项调整：下发语音、不下发语音、其他、快速拍照共计4个选项。",
        },
      {
          time: "2024年3月6日更新内容：",
          content:
            "1、gps更新管理行为报表-车辆抽查统计报表，在车辆明细统计报表中新增了抽查人筛选，便于检查监控人员每日抽查车辆详情",
        },
      {
          time: "2024年1月11日更新内容：",
          content:
            "1、更新了更新的视频服务和视频播放器，让视频打开更快，播放更稳定流畅",
        },
        {
          time: "2023年12月22日更新内容：",
          content:
            "1、更新了公交智能调度，让调度更简洁。<br/>2、电子地图增加了站点显示。<br/>3、智能排班，优化排班操作，使操作更简洁。<br/>4、加入了路队长调度车辆的相关操作"
        },
      {
          time: "2023年5月22日更新内容：",
          content: "1、新增车辆、驾驶员变更记录日志查询"
        },
        {
          time: "2023年5月4日更新内容：",
          content:
            "1、优化了车辆资料到期提醒方式。<br/>2、优化了驾驶员资料到期提醒方式"
        },
        {
          time: "2023年4月17日更新内容：",
          content: "1、取消报警合并。<br/>2、修复一些其他已知问题"
        },
        {
          time: "2023年2月18日更新内容：",
          content: "1、新增IC卡使用统计功能，可统计查询驾驶员详细的登退签次数。"
        },
        {
          time: "2022年11月24日更新内容：",
          content:
            "1、新增报警明细查询功能。<br/>2、报警查询功能中，新增报警来源（平台和终端）。"
        },
        {
          time: "2022年11月16日更新内容：",
          content:
            "1、车辆轨迹中明确标识了定位点是否是补传定位。<br/>2、车辆轨迹中针对不定位的点位进行了标红。<br/>3、车辆轨迹中增加了查看停车休息点。<br/>4、新增锐明设备写卡功能。"
        },
        {
          time: "2022年9月21日更新内容：",
          content:
            "1、新增工单助手功能，可以在ITS平台提交报修、报废、下线等操作。"
        },
        {
          time: "2021年10月18日更新内容：",
          content:
            "1、报警配置优化，更新多种报警提醒，报警升级、按等级自动处理等。<br/>2、报警处理，报警处理页面只留存最近1小时未处理的报警及其展示内容，方便处理报警；报警处理整体分为下发语音和不下发语音两种。报警结果确认需要人工分析报警是误报还是真实报警，报警结果确认纳入报警统计。<br/>3、原报警处理页面改改为报警查询，可以查询历史报警记录和处理历史报警。<br/>4、报警统计相关报表，新增报警统计是否排除误报选项，在统计车辆和驾驶员违规时更快捷准确。<br/>5、综合监控-离线提醒列表中新增故障列。离线时有故障报警的车辆需要重点关注。<br/>6、设备故障统计报表，新增故障详情查看，详情中展示的是该车选择日期当日的某种故障详细上报时间。<br/>7、新增报警报备功能，车辆有特殊任务时，经过与相关部门报备，可以录入报备信息，平台不产生疲劳、超速、夜间禁行报警，同时在报备时间内的车辆，终端产生的报警也会自动处理。"
        },
        {
          time: "2021年9月15日更新内容：",
          content:
            "1、优化区域围栏设置功能，及区域报警解析。<br/>2、优化视频服务，开放部分用户内测。<br/>3、优化部分细节。"
        },
        {
          time: "2021年8月20日更新内容：",
          content:
            "1、修复部标终端查询终端参数超时问题。<br/>2、报警处理-报警列表中，新增合并报警中的最高速度字段。"
        },
        {
          time: "2021年8月3日更新内容：",
          content:
            "1、终端管理，修改终端，新增终端生产日期字段。<br/>2、adas人脸识别报警用人脸识别报警。<br/>3、旅游车行程录入后，在行程中的时间内会作为综合监控的路线显示。<br/>4、轨迹回放优化：新增了轨迹中驾驶员的信息和轨迹中的报警信息，修改了停车点的逻辑为速度为0即停车，以及加载速度的优化调整。<br/>5、公交车辆到离站改为异步处理方式。"
        },
        {
          time: "2021年7月23日更新内容：",
          content:
            "1、调度文本模板新增删除功能，同时修复了标题重复的判定逻辑。<br/>2、adas故障统计报表统计每日每类报警上报次数和最新上报时间。<br/>3、新增终端离线升级功能，实现托管式升级体验。<br/>4、新增浏览器在一定时间内保持登录效果。<br/>5、新增路网图查询状态及其最新日志查询功能。<br/>6、新增adas终端汇报策略参数设置。"
        },
        {
          time: "2021年7月20日更新内容：",
          content:
            "1、首页新闻版本改为平台发布日志。<br/>2、公交调度新增一个实时调度页面，具有更好的体验。"
        },
        {
          time: "2021年7月8日更新内容：",
          content:
            "1、出租车人脸识别，照片和司机照片对比功能优化。<br/>2、主动安全报警统计，车辆报警分析和驾驶员行为分析中，新增驾驶员和车辆评分。<br/>3、主动安全报警详情中，新增获取地址功能。<br/>4、修复危险品电子运单不能删除的bug。<br/>5、驾驶员管理中，新增驾驶员聘用信息和奖惩信息。"
        },
        {
          time: "2021年6月28日更新内容：",
          content:
            "1、新版视频服务上线内测，开放少量方便沟通意见的外部用户使用。<br/>2、原GPS报表修改为统计ADAS，不在GPS的车辆，但是安装了ADAS的车辆也会统计。<br/>3、平台转发上级平台查岗功能内测，上级平台发起抽查时，对应人员需要及时处理。"
        },
        {
          time: "2021年6月8日更新内容：",
          content:
            "1、终端参数设置，疲劳驾驶参数配置增加报警开关。<br/>2、新增加ADAS报警附件完整率统计报表。<br/>3、ADAS终端参数设置-疲劳驾驶参数新增恢复默认按钮，保障设置异常的可以修正。"
        },
        {
          time: "2021年4月8日更新内容：",
          content:
            "1、修改了终端设备写卡模板和指令。<br/>2、车辆上下线提醒bug修复。<br/>3、管理行为分析-车辆调度统计-车辆明细表，增加车辆调度详情。<br/>4、综合监控中，轨迹中取消了地址栏，需要查看定位点位置，请点击表格会在地图上显示定位点位置和中文地址，导出轨迹时，需要选择是否转换地址。不转换则不会有地址，选择转换，才会显示地址。<br/>5、综合监控中树形结构的交互体验修改。"
        }
      ],
      httpHost: "",
      flag: true,
      scrollDistence: 0
    };
  },
  computed: {
    weekSign() {
      const week = new Date().getDay();
      let W = "";
      switch (week) {
        case 0:
          W = "天";
          break;
        case 1:
          W = "一";
          break;
        case 2:
          W = "二";
          break;
        case 3:
          W = "三";
          break;
        case 4:
          W = "四";
          break;
        case 5:
          W = "五";
          break;
        case 6:
          W = "六";
          break;
        default:
          break;
      }
      return W;
    }
  },
  methods: {
    // 初始化与绑定监听事件方法
    scrollInit() {
      // 获取要绑定事件的元素
      this.scrollDiv = document.getElementById("nav");
      this.scrollInner = document.querySelector(".nav-inner");
      this.maxScrollDis =
        this.scrollDiv.clientWidth - this.scrollInner.clientWidth;
      if (this.maxScrollDis >= 0) this.maxScrollDis = 0;
      // this.mallDiv = document.querySelector(".log-list");
      // document.addEventListener('DOMMouseScroll', handler, false)
      // 添加滚轮滚动监听事件，一般是用下面的方法，上面的是火狐的写法
      this.scrollDiv.addEventListener("mousewheel", this.scrollHandler, false);
      // 滚动事件的出来函数
    },
    scrollHandler(event) {
      const self = this;
      const detail = event.wheelDelta || event.detail;
      const moveForwardStep = 1;
      const moveBackStep = -1;
      let step = 0;
      // console.log(event.wheelDelta);
      // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
      if (detail < 0) {
        step = moveBackStep * 100;
      } else {
        step = moveForwardStep * 100;
      }
      this.scrollDistence += step;
      if (this.scrollDistence > 0) this.scrollDistence = 0;
      if (this.scrollDistence < this.maxScrollDis)
        this.scrollDistence = this.maxScrollDis;
      if (self.flag) {
        this.scrollInner.style.left = this.scrollDistence + "px";
      }
    },
    //优化滚动条相互影响问题
    fixed() {
      const scrollInnerDiv = document.querySelector(".otherStyle");
      scrollInnerDiv.onmouseenter = () => {
        this.flag = false;
      };
      scrollInnerDiv.onmouseleave = () => {
        this.flag = true;
      };
    },
    toUrl(url) {
      window.open(url);
    },
    // getTime() {
    //   const d = new Date().valueOf();
    //   return moment(d).format("HH:mm");
    // },
    /** 跳转系统 */
    toSystem(key) {
      let token =
        sessionStorage.getItem("token") || localStorage.getItem("token");
      switch (key) {
        case 1:
          this.getAllMenu("gps", "projectGps/");
          break;
        case 2:
          this.getAllMenu("bus", "projectBus/");
          break;
        case 3:
          this.getAllMenu("taxi", "projectTaxi/");
          break;
        case 4:
          this.getAllMenu("resourse", "projectResourse/");
          break;
        case 5:
          // this.getAllMenu("record", "projectRecord/");
          this.getAllMenu("projectScheduling", "projectScheduling/");
          //更改为821调度中心
          break;
        case 6:
          //融合营销
          this.getAllMenu("projectTravel", "projectTravel/");
          break;
        case 7:
          //绩效考核
          this.getAllMenu("assess", "assess/");
          break;
        case 8:
          //经营分析
          this.getAllMenu("dataIntelligence", "data-pc/");
          break;
        case 10:
          // 安云财务
          this.getAllMenu("workOrder_bill", "operate/");
          break;
        case 11:
          // 智慧经营
          this.getAllMenu("smart_marketing", "inviteAward/");
          break;
        default:
          break;
      }
    },
    /* 动态获取菜单 */
    getAllMenu(key, menu) {
      const self = this;
      let menuList = JSON.parse(localStorage.getItem("menuList")).subMenuTrees;
      let length = menuList.length;
      let i = 0;
      menuList.forEach(element => {
        if (element.url === key) {
          if (key === "projectTravel") {
            //融合营销
            if (element.subMenuTrees) {
              let url = `https://gytravel.scgycx.net/manage/#/login?token=${this.token}`;
              if (process.env.VUE_APP_ENV != "pro") {
                url = `https://testgytravel.scgycx.net/manage/#/login?token=${this.token}`;
              }
              window.open(url);
            } else {
              this.$message.warning("无权限，请联系管理员！");
            }
          } else if (key === "assess") {
            const url = `${this.httpHost}${baseConfig.AssessPageBaseUrl}?token=${this.token}`; //test
            // const url = `http://its.inheritech.top/jxkh?token=${this.token}`; //pro
            element.subMenuTrees
              ? (window.location.href = url)
              : this.$message.warning("无权限，请联系管理员！");
          } else if (key === "dataIntelligence") {
            // } else if (key === "operate") {
            // const url = `${this.httpHost}${baseConfig.OperatePageBaseUrl}?token=${this.token}`;
            // // const url = `http://its.inheritech.top/jyfx?token=${this.token}`;
            // element.subMenuTrees
            //   ? (window.location.href = url)
            //   : this.$message.warning("无权限，请联系管理员！");
            let port = window.location.port;
            const url = `${this.httpHost}${
              port ? ":" + port : ""
            }/${menu}?token=${this.token}`;
            // const url = `http://its.inheritech.top/jyfx?token=${this.token}`;

            element.subMenuTrees
              ? (window.location.href = url)
              : this.$message.warning("无权限，请联系管理员！");
          } else if (key === "projectScheduling") {
            //821调度中心
            if (element.subMenuTrees) {
              let url = `http://821.inheritech.top/821/login?token=${this.token}`;
              // let url = `http://its.inheritech.top/821/login?token=${this.token}`;

              if (process.env.VUE_APP_ENV != "pro") {
                url = `http://192.168.0.158:12906/821/login?token=${this.token}`;
              }
              window.open(url);
            } else {
              this.$message.warning("无权限，请联系管理员！");
            }
          } else if (key === "workOrder_bill") {
            // 安云财务
            if (element.subMenuTrees) {
              let url = `http://its.inheritech.top/fortune/#/?token=${this.token}`;
              if (process.env.VUE_APP_ENV != "pro") {
                url = `http://192.168.0.158:12913/#/?token=${this.token}`;
              }
              window.open(url);
            } else {
              this.$message.warning("无权限，请联系管理员！");
            }
          } else if (key === "smart_marketing") {
            // 智慧经营
            if (element.subMenuTrees) {
              let url = `https://gytravel.scgycx.net/smartMarketing/manage/#/?token=${this.token}`;
              if (process.env.VUE_APP_ENV != "pro") {
                url = `http://192.168.0.199:8919/#/?token=${this.token}`;
              }
              window.open(url);
            } else {
              this.$message.warning("无权限，请联系管理员！");
            }
          } else {
            if (element.subMenuTrees) {
              const activeMenu = menuRecursion(element.subMenuTrees[0]);
              self.$router.push(menu + activeMenu.url);
            } else {
              this.$message.warning("无权限，请联系管理员！");
            }
          }
        } else {
          i++;
        }
      });
      if (length == i) {
        this.$message.warning("无权限，请联系管理员！");
      }
    },
    confirmLogout() {
      loginOut({
        token: sessionStorage.getItem("token") || localStorage.getItem("token")
      })
        .then(res => {
          if (res.code === 1000) {
            localStorage.removeItem("vuex");
            this.$store.dispatch("clear_all");
            this.$store.dispatch("clear_MenuCtr");
            this.$store.dispatch("clear_user");
            this.$store.dispatch("clear_Menus");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("username");
            sessionStorage.removeItem("realname");
            this.$router.push({ path: "/login" });
            this.$yhsdp.CloseSocketLink();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    const { hostname, protocol } = window.location;
    this.httpHost = `${protocol}//${hostname}`;
    // 调用初始化方法，这里一定是在DOM加载之后调用
    this.scrollInit();
    this.fixed();
    // 页面加载完后显示当前时间
    // this.queryNews();
    this.realTime = formatHour(new Date());
    this.realname =
      sessionStorage.getItem("realname") || localStorage.getItem("realname");
    this.timer = setInterval(() => {
      this.realTime = formatHour(new Date());
    }, 1000);
  },
  beforeDestroy() {
    this.scrollDiv.removeEventListener("mousewheel", this.scrollHandler);
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
};
</script>

<style lang="scss" scoped>
.platform {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #8d5783;
  background: -webkit-linear-gradient(
    left,
    #8d5783,
    #0056fd
  ); /* Safari 5.1-6.0 */
  background: -o-linear-gradient(right, #8d5783, #0056fd); /* Opera 11.1-12.0 */
  background: -moz-linear-gradient(
    right,
    #8d5783,
    #0056fd
  ); /* Firefox 3.6-15 */
  background: linear-gradient(to right, #8d5783, #0056fd); /* 标准语法 */
  position: relative;
  @include themify() {
    .container-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      @keyframes bounce1 {
        from {
          transform: scale3d(0.84, 0.85, 0.87) translate3d(0, 0, 0);
        }
        to {
          transform: scale3d(0.9, 0.9, 0.9) translate3d(-90px, 30px, -40px);
        }
      }
      @keyframes bounce2 {
        from {
          transform: scale3d(0.9, 0.9, 0.9) translate3d(0, 0, 0);
        }
        to {
          transform: scale3d(0.84, 0.85, 0.87) translate3d(-40px, -40px, 200px);
        }
      }
      @keyframes bounce3 {
        from {
          transform: scale3d(0.84, 0.85, 0.87) translate3d(-40px, -40px, 40px);
        }
        to {
          transform: scale3d(0.8, 0.8, 0.8) translate3d(0, 0, 0);
        }
      }
      .ball1 {
        animation: bounce1 2.5s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }
      .ball2 {
        animation: bounce2 2s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }
      .ball3 {
        animation: bounce3 3.5s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }
      & > p {
        position: relative;
        width: 30%;
        span {
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.1);
          position: absolute;
        }
      }
      & > p:nth-child(1) {
        span {
          width: 35vw;
          height: 35vw;
          top: -10vw;
          left: -2vw;
        }
      }
      & > p:nth-child(2) {
        span {
          width: 30vw;
          height: 30vw;
          top: 5vw;
          left: -13vw;
        }
      }
      & > p:nth-child(3) {
        flex: 1;
        span {
          width: 30vw;
          height: 30vw;
          bottom: -5vw;
          right: -11vw;
        }
      }
    }
    .platform-container {
      width: 100%;
      margin: 0 auto;
      color: #fff;
      z-index: 4;
      position: absolute;
      top: 0;
      .header {
        height: 15vh;
        width: 80%;
        margin: 0 auto;
        margin-top: 10vh;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        .left {
          width: 6%;
          section {
            h3 {
              font-size: (60rem/14);
              font-weight: 400;
              line-height: (45rem/14);
              margin: 0 0 1vh 0;
            }
            p {
              font-size: (14rem/14);
              line-height: (15rem/14);
              margin: 0;
              display: flex;
              justify-content: space-around;
              width: 9vw;
            }
          }
        }
        .center {
          width: 70%;
          display: flex;
          align-items: center;
          section {
            height: 9vh;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            img {
              height: 100%;
            }
            h3 {
              width: 100%;
              font-size: (36rem/14);
              font-weight: 400;
              margin: 0;
            }
            p {
              width: 100%;
              color: #f0f0f0;
              font-size: (14rem/14);
              letter-spacing: 1.11px;
              margin: 0;
            }
          }
        }
        .right {
          width: 10%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          section {
            margin-right: 1vh;
            p:nth-child(1) {
              font-size: (16rem/14);
            }
            p:nth-child(2) {
              font-size: (12rem/14);
              margin-top: 1vh;
              cursor: pointer;
              i {
                font-size: 12px;
              }
            }
          }
          section:nth-child(1) {
            width: 4vh;
            height: 4vh;
            border-radius: 50%;
            img {
              width: 100%;
            }
          }
        }
      }

      .item {
        overflow: hidden;
        border-radius: 4px;
        box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.1);
        & > section {
          width: 100%;
          height: 100%;
          & > p {
            height: 50%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-end;
            & > span {
              height: 4rem;
              width: 4rem;
              border-radius: 50%;
              img {
                width: 100%;
              }
            }
          }
          & > h3 {
            width: 100%;
            font-size: 1.5rem;
            font-weight: normal;
            margin: 1vh 0;
            text-align: center;
          }
        }
      }
      .item1 {
        grid-area: a;
        background-color: #336ffe;
        padding-right: 0;
        scroll-behavior: smooth;
      }
      .item2 {
        grid-area: b;
        background-color: #336ffe;
      }
      .item3 {
        grid-area: d;
        background-color: #ea853e;
      }
      .item7 {
        grid-area: g;
        background-color: #ead13e;
      }
      .item10 {
        grid-area: j;
        background-color: #1c9da5;
      }
      .item4 {
        grid-area: c;
        background-color: #4fb638;
      }
      .item5 {
        grid-area: e;
        background-color: #9154e7;
      }
      .item6 {
        grid-area: f;
        background-color: #ee524f;
      }
      .item8 {
        grid-area: h;
        background-color: #6b86e9;
      }
      .item9 {
        grid-area: i;
        background-color: #4d7efd;
      }
      .item11 {
        grid-area: k;
        background-color: #ed5df9;
      }

      #nav {
        width: 100%;
        height: 64vh;
        // overflow-x: scroll;
        white-space: nowrap;
        position: relative;
        // scroll-behavior: smooth;
        .nav-inner {
          position: absolute;
          transition: all 0.5s ease-out;
          left: 0;
          > * {
            display: inline-block;
            vertical-align: top;
          }
        }
        .main {
          height: 64vh;
          padding: 2vh 0;
          column-gap: 20px;
          white-space: nowrap;
          box-sizing: border-box;
          > * {
            display: inline-block;
            vertical-align: top;
          }
          .otherStyle {
            height: 100%;
            border-radius: 4px;
            width: 420px;
            box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.1);
            background: #336ffe;
            .log-Info {
              height: 100%;
              display: flex;
              flex-direction: column;
              .log-list {
                width: 100%;
                line-height: 24px;
                height: 100%;
                overflow-y: auto;
                scroll-behavior: smooth;
                padding: 20px 20px 10px 30px;
                box-sizing: border-box;
                .log-p {
                  overflow-x: hidden;
                  white-space: normal;
                  word-wrap: break-word;
                  word-break: normal;
                  line-height: 25px;
                }
                .log-title {
                  font-size: 22px;
                  line-height: 35px;
                  margin-bottom: 8px;
                }
              }
            }
          }
          .item-right {
            height: 100%;
            width: 100%;
            grid-area: b;
            display: grid;
            grid-auto-columns: 2fr 1.5fr 1.5fr 1.5fr 1.5fr 1.5fr;
            grid-template-rows: repeat(2, 1fr);
            grid-template-areas:
              "b c c h k g "
              "d e f j j i";
            grid-gap: 20px 20px;
            .item {
              cursor: pointer;
              transition: all 0.2s;
              z-index: 10;
              &:hover {
                transform: scale(1.08, 1.08) translateY(-3px);
                z-index: 100;
              }
            }
          }
        }
        .grids {
          height: 100%;
          width: 110vw;
          margin: 0 20px;
        }
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
          background: transparent;
        }
      }
      .none-box {
        width: 10vw;
        height: 100%;
        user-select: none;
      }
      .footer {
        height: 10vh;
        line-height: 10vh;
        text-align: center;
      }
    }
  }
}
</style>
