var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "platform" }, [
    _vm._m(0),
    _c("div", { staticClass: "platform-container" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "left" }, [
          _c("section", [
            _c("h3", [_vm._v(_vm._s(_vm.realTime))]),
            _c("p", [
              _vm._v(" " + _vm._s(_vm.date) + " "),
              _c("span", [_vm._v("星期" + _vm._s(_vm.weekSign))])
            ])
          ])
        ]),
        _vm._m(1),
        _c("div", { staticClass: "right" }, [
          _vm._m(2),
          _c("section", [
            _c("p", [_vm._v(_vm._s(_vm.realname))]),
            _c("p", { on: { click: _vm.confirmLogout } }, [
              _c("i", { staticClass: "iconfont its_tuichu" }),
              _vm._v("退出 ")
            ])
          ])
        ])
      ]),
      _c("div", { attrs: { id: "nav" } }, [
        _c("div", { staticClass: "nav-inner" }, [
          _c("div", { staticClass: "none-box" }),
          _c("div", { staticClass: "main" }, [
            _c("div", { staticClass: "otherStyle" }, [
              _c("div", { staticClass: "log-Info" }, [
                _c("img", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    src: require("../../assets/images/home/Bitmap.png"),
                    alt: ""
                  }
                }),
                _c(
                  "div",
                  { staticClass: "log-list" },
                  _vm._l(_vm.logList, function(ele, index) {
                    return _c("div", { key: index, staticClass: "log-item" }, [
                      _c("p", { staticClass: "log-title" }, [
                        _vm._v(_vm._s(ele.time))
                      ]),
                      _c("div", {
                        staticClass: "log-p",
                        domProps: { innerHTML: _vm._s(ele.content) }
                      }),
                      _c("br")
                    ])
                  }),
                  0
                )
              ])
            ]),
            _c("div", { staticClass: "grids" }, [
              _c("div", { staticClass: "item-right" }, [
                _c(
                  "div",
                  {
                    staticClass: "item item2",
                    on: {
                      click: function($event) {
                        return _vm.toSystem(1)
                      }
                    }
                  },
                  [_vm._m(3)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item item3",
                    on: {
                      click: function($event) {
                        return _vm.toSystem(2)
                      }
                    }
                  },
                  [_vm._m(4)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item item7",
                    on: {
                      click: function($event) {
                        return _vm.toSystem(7)
                      }
                    }
                  },
                  [_vm._m(5)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item item4",
                    on: {
                      click: function($event) {
                        return _vm.toSystem(3)
                      }
                    }
                  },
                  [_vm._m(6)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item item10",
                    on: {
                      click: function($event) {
                        return _vm.toSystem(10)
                      }
                    }
                  },
                  [_vm._m(7)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item item5",
                    on: {
                      click: function($event) {
                        return _vm.toSystem(4)
                      }
                    }
                  },
                  [_vm._m(8)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item item6",
                    on: {
                      click: function($event) {
                        return _vm.toSystem(6)
                      }
                    }
                  },
                  [_vm._m(9)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item item8",
                    on: {
                      click: function($event) {
                        return _vm.toSystem(8)
                      }
                    }
                  },
                  [_vm._m(10)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item item11",
                    on: {
                      click: function($event) {
                        return _vm.toSystem(11)
                      }
                    }
                  },
                  [_vm._m(11)]
                ),
                _c(
                  "div",
                  {
                    staticClass: "item item9",
                    on: {
                      click: function($event) {
                        return _vm.toSystem(5)
                      }
                    }
                  },
                  [_vm._m(12)]
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "none-box" })
        ])
      ]),
      _c("div", { staticClass: "footer" }, [
        _vm._v("Copyright © 2021 四川英海瑞特科技有限公司")
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-bg" }, [
      _c("p", [_c("span", { staticClass: "ball1" })]),
      _c("p", [_c("span", { staticClass: "ball2" })]),
      _c("p", [_c("span", { staticClass: "ball3" })])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "center" }, [
      _c("section", [
        _c("img", {
          attrs: {
            src: require("../../assets/images/home/solutions.png"),
            alt: "",
            srcset: ""
          }
        })
      ]),
      _c("section", [
        _c("h3", [_vm._v("英海瑞特「ITS」服务平台")]),
        _c("p", [_vm._v("INHERITECH INTELLIGENT TRANSPOORTATION SYSTEM")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("img", {
        attrs: {
          src: require("../../assets/images/home/header.png"),
          alt: "",
          srcset: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("p", [
        _c("span", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/home/home1.png"),
              alt: "",
              srcset: ""
            }
          })
        ])
      ]),
      _c("h3", [_vm._v("世畅纬佳车辆监控平台")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("p", [
        _c("span", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/home/home2.png"),
              alt: "",
              srcset: ""
            }
          })
        ])
      ]),
      _c("h3", [_vm._v("公交主动安全智能调度系统")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("p", [
        _c("span", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/home/home7.png"),
              alt: "",
              srcset: ""
            }
          })
        ])
      ]),
      _c("h3", [_vm._v("绩效考核")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("p", [
        _c("span", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/home/home3.png"),
              alt: "",
              srcset: ""
            }
          })
        ])
      ]),
      _c("h3", [_vm._v("出租车服务平台")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("p", [
        _c("span", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/home/home15.png"),
              alt: "",
              srcset: ""
            }
          })
        ])
      ]),
      _c("h3", [_vm._v("安云财务")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("p", [
        _c("span", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/home/home4.png"),
              alt: "",
              srcset: ""
            }
          })
        ])
      ]),
      _c("h3", [_vm._v("资源管理")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("p", [
        _c("span", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/home/homegysm.png"),
              alt: "",
              srcset: ""
            }
          })
        ])
      ]),
      _c("h3", [_vm._v("融合营销")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("p", [
        _c("span", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/home/home8.png"),
              alt: "",
              srcset: ""
            }
          })
        ])
      ]),
      _c("h3", [_vm._v("数据智能服务")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("p", [
        _c("span", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/home/home16.svg"),
              alt: "",
              srcset: ""
            }
          })
        ])
      ]),
      _c("h3", [_vm._v("智慧经营")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("p", [
        _c("span", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/home/home5.svg"),
              alt: "",
              srcset: ""
            }
          })
        ])
      ]),
      _c("h3", [_vm._v("车辆调度")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }